<template>
    <div class="checkPart">
        <div class="taskDetailPart flex-between" v-if="timeData.length">
            <div class="timePart scrollbar">
                <div class="timezone">
                    <div
                        v-for="(y, ind) in timeData"
                        :key="ind"
                        class="timelist"
                    >
                        <div
                            :class="{
                                currentYear: y.YEAR === currentYear,
                            }"
                            class="year"
                        >
                            <p @click="checkYear(y)">{{ y.YEAR }}</p>
                            <span></span>
                        </div>
                        <!-- 古茶树 这里没有 异常 -->
                        <!-- 月日 -->
                        <!-- 
                            RWZT
                            0	未完成
                            1	已完成
                            2	已过期
                            -->
                        <!-- 
                                SJZT
                                2	审核通过
                                3	审核不通过
                                -->
                        <div v-for="(m, key) in y.DATALIST" :key="key">
                            <div
                                v-for="(d, index) in m.LIST"
                                v-show="num === key"
                                :key="index"
                                :class="{
                                    currentTask: d.RWID === currentTask,
                                    day0: d.RWZT === 0, //未完成
                                    day1: d.RWZT === 1, //已完成
                                    day2: d.RWZT === 2, //已过期
                                }"
                                class="day"
                                @click="checkDay(d, ind, key, index)"
                            >
                                <p class="timeShow">
                                    {{ d.RWJSSJ ? d.RWJSSJ.slice(5, 11) : "" }}
                                </p>
                                <span></span>
                                <!-- 时间轴右侧 数据状态 -->
                                <div class="">
                                    <p v-if="d.RWZT === 0">未完成</p>
                                    <p v-if="d.RWZT === 1">已完成</p>
                                    <p v-if="d.RWZT === 2">已过期</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="infoPart">
                <img
                    width="16px"
                    class="imgState imgStateRight"
                    src="@image/monitoring_cloud/trangle.png"
                />
                <div class="content">
                    <div class="title">古茶树监测</div>
                    <el-form label-width="140px" @submit.native.prevent>
                        <div class="bottomPart scrollbar">
                            <!-- 审核状态 -->
                            <img
                                class="sh-pic"
                                :src="
                                    require(`@image/monitoring_cloud/sh_${bottomData.SHZT}.png`)
                                "
                                alt=""
                            />

                            <el-form-item
                                v-for="(item, i) in formList"
                                :key="i"
                                :label="item.label"
                                :prop="item.value"
                                style="margin-bottom: 0"
                            >
                                {{ item.value ? bottomData[item.value] : "" }}
                            </el-form-item>
                            <el-form-item label="照片:" prop="">
                                <div class="imgPart scrollbar">
                                    <SnFileDialog :fileData="fileData">
                                    </SnFileDialog>
                                </div>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
        <div v-else class="taskDetailPartNo">
            <SnNoData
                width="350px"
                :noDataState="true"
                noDataText="暂无巡查数据"
                color="#e4e4e4"
            ></SnNoData>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { $AXIOS } from "@request/http.js";

export default {
    name: "",
    components: {},
    prop: {
        detail: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            //时间线 部分
            timeData: [],
            num: 2,
            currentYear: "",
            currentTask: "",
            taskRwzt: "",
            curranrPage: 0,
            allPage: 0,
            bottomData: {},
            formList: [
                {
                    label: "采集编号：",
                    value: "BH",
                },
                {
                    label: "资源名称：",
                    value: "SZ_ZWM",
                },
                {
                    label: "学名：",
                    value: "SZ_BM",
                },
                {
                    label: "生长地点：",
                    value: "SZDD",
                },
                {
                    label: "经度：",
                    value: "DJ",
                },
                {
                    label: "纬度：",
                    value: "BW",
                },
                {
                    label: "海拔高度(m)：",
                    value: "HB",
                },
                {
                    label: "植被：",
                    value: "ZB",
                },
                {
                    label: "遮阴度(％)：",
                    value: "ZYD",
                },
                {
                    label: "树势：",
                    value: "SS",
                },
                {
                    label: "地形位置：",
                    value: "DXWZ_DESC",
                },
                {
                    label: "分枝密度：",
                    value: "FZMD_DESC",
                },
                {
                    label: "树型：",
                    value: "SX_DESC",
                },
                {
                    label: "树姿：",
                    value: "SZ_DESC",
                },
                {
                    label: "嫩枝茸毛：",
                    value: "NZRM_DESC",
                },
                {
                    label: "寄生植物：",
                    value: "JSZW",
                },
                {
                    label: "最低分枝高(cm)：",
                    value: "ZDFZG",
                },
                {
                    label: "树高(m)：",
                    value: "SG",
                },
                {
                    label: "树幅(m)：",
                    value: "SF",
                },
                {
                    label: "最大干围(cm)：",
                    value: "ZDGW",
                },
                {
                    label: "基部干围(cm)：",
                    value: "JBGW",
                },
                {
                    label: "叶长(cm)×宽(cm)：",
                    value: "YCYK",
                },
                {
                    label: "叶片大小：",
                    value: "YPDX_DESC",
                },
                {
                    label: "叶形：",
                    value: "YX_DESC",
                },
                {
                    label: "叶色：",
                    value: "YS_DESC",
                },
                {
                    label: "叶基：",
                    value: "YJ_DESC",
                },
                {
                    label: "叶脉对数(对)：",
                    value: "YMDS",
                },
                {
                    label: "叶身：",
                    value: "YESHEN_DESC",
                },
                {
                    label: "叶尖：",
                    value: "YEJIAN_DESC",
                },
                {
                    label: "叶面：",
                    value: "YM_DESC",
                },
                {
                    label: "叶缘：",
                    value: "YY_DESC",
                },
                {
                    label: "叶质：",
                    value: "YZ_DESC",
                },
                {
                    label: "叶背主脉茸毛：",
                    value: "YBZMRM_DESC",
                },
                {
                    label: "叶齿形态：",
                    value: "YCXT_DESC",
                },
                {
                    label: "芽叶色泽：",
                    value: "YAYE_DESC",
                },
                {
                    label: "芽叶茸毛：",
                    value: "YYR_DESC",
                },
                {
                    label: "耕作情况：",
                    value: "GZQK",
                },
                {
                    label: "病虫害发生情况：",
                    value: "BCHFSQK",
                },
            ],
            fileData: {
                mp3List: [],
                mp4List: [],
                imgList: [],
                othersPart: {
                    key: "",
                    name: "",
                    list: [],
                },
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        ...mapMutations({}),
        ...mapActions(["GetGcsTaskTimeList", "getTaskDetails"]),
        async getTimeList(obj) {
            let result = await this.GetGcsTaskTimeList({
                gcsId: obj.ID,
            });
            if (result.ISSUCCESS) {
                this.timeData = result.RESULTVALUE;
                if (result.RESULTVALUE.length) {
                    this.currentYear = result.RESULTVALUE[0].YEAR;
                }
                if (
                    result.RESULTVALUE.length &&
                    result.RESULTVALUE[0].DATALIST.length &&
                    result.RESULTVALUE[0].DATALIST[0].LIST.length
                ) {
                    this.checkDay(result.RESULTVALUE[0].DATALIST[0].LIST[0]);
                }
            }
        },
        checkYear(value) {
            this.currentYear = value.YEAR;
            this.checkDay(value.DataList[0].List[0]);
        },
        checkDay(value, key = 0, ind = 0, index = 0) {
            this.taskRwzt = value.RWZT;
            this.currentTask = value.RWID;
            this.yearInd = key;
            this.MonthInd = ind;
            this.DayInd = index;
            this.getDataDetail(value);
        },
        async getDataDetail(obj) {
            let result = await this.getTaskDetails({
                jcxbid: obj.JCDXBID,
                jcpzid: obj.ID,
                rwid: obj.RWID,
            });
            if (result.dt) {
                this.bottomData = result.dt[0];
                if (this.bottomData.SHZT == null) {
                    this.bottomData.SHZT = 0;
                }
            }
            this.fileData.mp3List = [];
            this.fileData.mp4List = [];
            this.fileData.imgList = [];
            if (result.fjbDtos) {
                result.fjbDtos.map((item) => {
                    let dw = item.CCLJ.substring(
                        item.CCLJ.lastIndexOf(".") + 1
                    );
                    switch (dw) {
                        case "mp3":
                            this.fileData.mp3List.push(item.CCLJ);
                            break;
                        case "mp4":
                            this.fileData.mp4List.push(item.CCLJ);
                            break;
                        case "jpg":
                        case "png":
                        case "jpeg":
                        case "gif":
                        case "tiff":
                            this.fileData.imgList.push(item.CCLJ);
                            break;
                        default:
                            this.fileData.othersPart.list.push(item);
                            break;
                    }
                });
            }
        },
        getShName(item) {
            let name = "";
            switch (item.SJZT) {
                case 2:
                    name = "审核通过";
                    break;
                case 3:
                    name = "审核不通过";
                    break;
                default:
                    name = "未审核";
                    break;
            }
            return name;
        },
    },
};
</script>
<style scoped lang="scss">
.checkPart {
    width: 100%;

    .taskDetailPart {
        width: 100%;
        height: 600px;
        margin-top: 10px;
        position: relative;
        .timePart {
            width: calc(30% - 10px);
            position: relative;
            margin-left: 16px;
            background-color: #fff;
            height: 100%;
            border: 1px solid #dee5ed;
            padding: 10px 0;
            .timezone {
                width: 2px;
                background: #bed1e0;
                margin: 0 auto;
                animation: heightSlide 2s linear;
                border: 1px solid transparent;
                .timelist {
                    .year {
                        position: relative;
                        margin-left: -8px;
                        margin-bottom: 18px;
                        margin-top: 20px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: #fff;
                        border: 4px solid #a0a0a0;
                        &.currentYear {
                            border: 4px solid #1560dd;
                        }
                        &.currentYear p,
                        &.currentYear p:after,
                        &.currentYear span {
                            background-color: #1560dd;
                        }
                        p {
                            position: absolute;
                            margin-left: -103px;
                            margin-top: -14px;
                            background: #a0a0a0;
                            color: #ffffff;
                            border-radius: 6px;
                            padding: 0 8px;
                            font-size: 26px;
                            cursor: pointer;
                            &:after {
                                content: "";
                                width: 12px;
                                height: 12px;
                                background-color: #a0a0a0;
                                position: absolute;
                                top: 12px;
                                right: -5px;
                                transform: rotate(45deg);
                            }
                        }

                        span {
                            width: 16px;
                            height: 2px;
                            background: #a0a0a0;
                            position: absolute;
                            left: -20px;
                            top: 3px;
                        }
                    }

                    .day {
                        height: 30px;
                        line-height: 30px;
                        display: block !important;
                        position: relative;
                        margin-left: -6px;
                        margin-bottom: 30px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 3px solid #fff;
                        &.currentTask .timeShow {
                            color: #1560dd;
                            font-weight: 700;
                        }
                        &.currentTask,
                        &.currentTask > span,
                        &.currentTask > div > p {
                            background-color: #1560dd;
                        }
                        .timeShow {
                            position: absolute;
                            margin-left: -50px;
                            margin-top: -10px;
                            font-size: 16px;
                            color: #45576b;
                            cursor: pointer;
                        }
                        span {
                            width: 30px;
                            height: 2px;
                            position: absolute;
                            right: -33px;
                            top: 2px;
                        }
                        div {
                            width: 70px;
                            height: 35px;
                            margin-left: 42px;
                            margin-top: -15px;
                            font-size: 26px;
                            p {
                                height: 35px;
                                line-height: 35px;
                                color: #fff;
                                border-radius: 6px;
                                cursor: pointer;
                                text-align: center;
                            }
                            .shFont {
                                font-size: 12px;
                                line-height: 18px;
                                height: 18px;
                                display: inline-block;
                                background: #fff;
                                border-radius: 5px;
                                margin: 1px;
                                width: calc(100% - 2px);
                            }
                            .ztName {
                                line-height: 22px;
                            }
                        }
                    }
                    .day0,
                    .day0 > span,
                    .day0 > div > p {
                        background-color: #ff8b5c;
                    }
                    .day1,
                    .day1 > span,
                    .day1 > div > p {
                        background-color: #96de00;
                    }
                    .day2,
                    .day2 > span,
                    .day2 > div > p {
                        background-color: #a0a0a0;
                    }
                    .day3,
                    .day3 > span,
                    .day3 > div > p {
                        background-color: #fa6f1b;
                    }
                    .day3 > div .shFont {
                        color: #fa6f1b;
                    }
                    .day4,
                    .day4 > span,
                    .day4 > div > p {
                        background-color: rgb(40, 205, 217);
                    }
                    .day4 > div .shFont {
                        color: rgb(40, 205, 217);
                    }
                    .day5,
                    .day5 > span,
                    .day5 > div > p {
                        background-color: rgb(111, 50, 255);
                    }
                    .day5 > div .shFont {
                        color: rgb(111, 50, 255);
                    }
                    .day3,
                    .day4,
                    .day5 {
                        margin-bottom: 36px;
                    }
                    .day3 > div > p,
                    .day4 > div > p,
                    .day5 > div > p {
                        height: 42px;
                        line-height: 18px;
                    }
                }
            }
            @-webkit-keyframes heightSlide {
                0% {
                    height: 0;
                }
                100% {
                    height: 300px;
                }
            }
        }
        .infoPart {
            width: calc(70% - 10px);
            position: relative;
            .imgState {
                position: absolute;
                top: 60px;
                left: 1px;
            }
            .content {
                width: calc(100% - 16px);
                margin-left: 16px;
                background-color: #f5f4f9;
                height: 100%;
                border: 1px solid #dee5ed;
                .title {
                    height: 35px;
                    line-height: 35px;
                    background-color: #336799;
                    text-align: left;
                    color: #fff;
                    padding-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .bottomPart {
                    padding: 0 10px;
                    position: relative;
                    height: 560px;
                    .sh-pic {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        z-index: 1;
                    }
                    .shButton {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                    .imgPart {
                        width: 760px;
                        max-height: 110px;
                    }
                    .footerPart {
                        padding: 0 20px;
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        position: relative;
                        .page {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                        }
                        .btnItem {
                            background: rgb(32, 89, 204);
                            color: rgb(255, 255, 255);
                            border-color: rgb(32, 89, 204);
                            box-shadow: rgb(32 89 204) 0px 1px 6px;
                            min-width: 75px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            border-radius: 20px;
                        }
                    }
                }
            }
        }
    }
    .taskDetailPartNo {
        position: relative;
        width: 100%;
        height: 200px;
    }
}
</style>
